<template>
  <div id="roadrunner-app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style lang="scss">
  @import url('./scss/cabinet-grotesk.css');

  /* some basic resetting */
  html,
  body {
    max-width: 100%;
    margin: 0;

    @media (max-width: 1200px) {
      overflow-x: hidden;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  /* app global styles */
  #roadrunner-app {
    font-family: Lato;
    color: #000;
  }

  /* reusable styles */
  .container {
    padding: 0 24px;
    max-width: 1300px;
    margin: 0 auto;
  }

  .admin-container {
    padding: 0 24px;
    max-width: 950px;
    margin: 0 auto;
  }

  .section {
    margin-top: 50px;
    padding-top: 150px;

    @media (max-width: 1200px) {
      margin-top: 0;
      padding-top: 110px;
    }
  }
</style>
